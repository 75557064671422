<template>
  <v-select
    appendToBody
    v-model="selected"
    :placeholder="placeholder"
    @input="onInput"
    :options="options"
    :multiple="multiple"
    hide-selected
  >
    <template slot="no-options">Нет результатов</template>
    <template #open-indicator="{ attributes }">
      <div v-bind="attributes">
        <DownIcon/>
      </div>
    </template>
  </v-select>
</template>

<script>

import DownIcon from '@/components/svg/DownIcon'
export default {
  name: 'SelectInput',
  components: { DownIcon },
  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    },
    value: {
      type: [Array, Object],
      default () {
        return []
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      timeout: null,
      showLoading: false,
      query: '',
      selected: []
    }
  },
  created () {
    this.selected = JSON.parse(JSON.stringify(this.value))
  },
  methods: {
    onInput (val) {
      this.$emit('input', val)
    }
  }
}
</script>
