<template>
  <ApolloQuery
    v-if="$store.state.me.role !== 'partner'"
    :query="require('@/graphql/queries/AllDirectories.gql')"
    :variables="{
      page,
      perPage,
      filter: getDirectoryFilter,
      sortField: sort.field,
      sortOrder: sort.order
    }"
    @result="doneLoadingDirectories">
    <template slot-scope="{ result: { error, data }, isLoading }">
      <div class="lsg-directorys">
        <div class="main__header">
          <div class="main__header__item left">
            <mq-layout :mq="['sm', 'xsm', 'md', 'lg']" class="filter_hide">
              <span class="btn btn_ico" @click="$store.state.data.directories.showFilter = !$store.state.data.directories.showFilter" :class="{'disable': $store.state.data.directories.showFilter}">
                <FilterIco/>
                Скрыть фильтры
              </span>
            </mq-layout>
          </div>
          <div class="main__header__item">
            <h1>{{ title }}</h1>
          </div>
          <div class="main__header__item right">
            <button v-if="$store.state.me.role === 'admin' || ['60b9c160d244fb050376f2c5', '620509f862194d88bf312556', '5f0d73200ffedb935485c9c0'].includes($store.state.me._id)" class="btn" @click="showDirectoryMenu">
              <span>Редактирование</span>
            </button>
          </div>
        </div>
        <div class="main__content">
          <div class="tbl tbl--divided">
            <div class="tbl__header" v-if="$store.state.data.directories.showFilter">
              <div class="tbl__filter">
                <label class="tbl__filter__item">
                  <SelectComponent
                    :options="$store.state.allSubdivisions"
                    :placeholder="'Подразделение'"
                    :multiple="true"
                    @input="onSubdivisionChange"
                    :value="filter.subdivision"
                  />
                </label>
                <label class="tbl__filter__item">
                  <SelectComponent
                    :options="$store.state.allDepartments"
                    :placeholder="'Отдел'"
                    :multiple="true"
                    @input="onDepartmentChange"
                    :value="filter.department"
                  />
                </label>
                <label class="tbl__filter__item">
                  <SelectComponent
                    :options="nameList || []"
                    :placeholder="'Поиск по имени'"
                    :multiple="true"
                    @input="onNameChange"
                    :value="filter.name"
                  />
                </label>
                <label class="tbl__filter__item">
                  <input type="text" placeholder="Поиск" v-model="filter.q">
                </label>
              </div>
              <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
                <a href="#" class="tbl__setting" @click.prevent="switchColumnModal">
                  <SettingsIcon/>
                </a>
              </mq-layout>
            </div>
            <div v-if="error" class="messages apollo">
              <div v-if="error" class="messages__status error">Ошибка загрузки данных. Обратитесь в тех. поддержку</div>
            </div>
            <div v-if="isLoading" class="messages apollo">
              <div v-if="isLoading" class="messages__status">Загрузка...</div>
            </div>
            <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
              <vue-good-table :columns="$store.state.data.directories.columns" :pagination-options="{enabled: true, perPage: perPage}" :rows="data ? data.allDirectories : []" @on-sort-change="sortingField2" :select-options="{ selectOnCheckboxOnly: true, selectionText: 'строка выбрана', clearSelectionText: 'очистить', enabled: true }"  @on-selected-rows-change="selectedRowsChange" compactMode>
                <template slot="table-row" slot-scope="props">
                  <router-link :to="{}" @click.native="openEmployee(props.row)" v-if="props.column.field === 'avatar'">
                    <img class="directory-avatar" :src="`${baseUrl}/avatar?user=${props.row.user_id}&minimal=true`" loading="lazy" alt="user">
                  </router-link>
                  <router-link :to="{}" @click.native="openEmployee(props.row)" v-if="props.column.field === 'name'">{{ props.row.name }}</router-link>
                  <router-link :to="{}" @click.native="openEmployee(props.row)" v-if="props.column.field === 'birthday'">{{ getDate( props.row.birthday) }}</router-link>
                  <router-link :to="{}" @click.native="openEmployee(props.row)" v-if="props.column.field === 'post'">{{ props.row.post }}</router-link>
                  <router-link :to="{}" @click.native="openEmployee(props.row)" v-if="props.column.field === 'workphone'">
                    <div class="directory-phone">
                      <span>{{ props.row.workphone }}</span>
                      <i @click="callPhone(props.row.workphone)" v-if="props.row.workphone" style="cursor: pointer">
                        <PhoneOutIcon />
                      </i>
                    </div>
                  </router-link>
                  <router-link :to="{}" @click.native="openEmployee(props.row)" v-if="props.column.field === 'personal_phone'">
                    <div class="directory-phone">
                      <span>{{ props.row.personal_phone }}</span>
                      <i @click="callPhone(props.row.personal_phone)" v-if="props.row.personal_phone" style="cursor: pointer">
                        <PhoneOutIcon />
                      </i>
                    </div>
                  </router-link>
                  <router-link :to="{}" @click.native="openEmployee(props.row)" v-if="props.column.field === 'additional_phone'">
                    <div class="directory-phone">
                      <span>{{ props.row.additional_phone }}</span>
                      <i @click="callPhone(props.row.additional_phone)" v-if="props.row.additional_phone" style="cursor: pointer">
                        <PhoneOutIcon />
                      </i>
                    </div>
                  </router-link>
                  <router-link :to="{}" @click.native="openEmployee(props.row)" v-if="props.column.field === 'email'">{{ props.row.email }}</router-link>
                  <router-link :to="{}" @click.native="openEmployee(props.row)" v-if="props.column.field === 'address'">{{ props.row.address }}</router-link>
                  <router-link :to="{}" @click.native="openEmployee(props.row)" v-if="props.column.field === 'department'">{{ props.row.department }}</router-link>
                  <router-link :to="{}" @click.native="openEmployee(props.row)" v-if="props.column.field === 'subdivision'">{{ props.row.subdivision }}</router-link>
                </template>
                <div slot="emptystate">Данных нет</div>
                <template slot="pagination-bottom" slot-scope="props">
                  <div class="vgt-wrap__footer vgt-clearfix">
                    <div class="footer__row-count vgt-pull-left">
                      <span class="footer__row-count__label">Показано</span>
                      <select @change="perPageChanged(props)" v-model="perPage" class="footer__row-count__select">
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="50">50</option>
                        <option :value="100">100</option>
                        <option :value="200">200</option>
                      </select>
                    </div>
                  </div>
                  <paginate :click-handler="setPage" :page-count="pageCount" :value="page + 1" prev-text="&lsaquo;" next-text="&rsaquo;" container-class="tbl__pagination vgt-pull-right">
                    <span slot="prevContent">
                      <ArrowLeftIcon/>
                    </span>
                    <span slot="nextContent">
                      <ArrowRightIcon/>
                    </span>
                  </paginate>
                </template>
              </vue-good-table>
            </mq-layout>
            <mq-layout :mq="[ 'xs', 'sm' ]" class="tbl__container tbl__mobile">
              <table>
                <tbody v-if="isLoading">
                <tr>
                  <td colspan="1">Загрузка...</td>
                </tr>
                </tbody>
                <tbody v-else-if="data && data.allDirectories && data.allDirectories.length">
                <tr :key="index" v-for="(directory, index) in data.allDirectories">
                  <td>
                    <div class="tbl__content__container">
                      <checkbox :class-name="$store.state.checkedDirectories.includes(directory) ? 'active' : ''" v-model="$store.state.checkedDirectories" :value="directory"></checkbox>
                      <div class="tbl__content">
                        <router-link :to="{}" @click.native="openEmployee(directory)">{{ directory.name }}</router-link>
                        <router-link :to="{}" @click.native="openEmployee(directory)">{{ getDate(directory.birthday) }}</router-link>
                        <router-link :to="{}" @click.native="openEmployee(directory)">{{ directory.post }}</router-link>
                        <router-link :to="{}">
                          <div @click="callPhone(directory.workphone)" class="directory-phone">
                            <span>{{ directory.workphone }}</span>
                            <i v-if="directory.workphone" style="cursor: pointer">
                              <PhoneOutIcon />
                            </i>
                          </div>
                        </router-link>
                        <router-link :to="{}">
                          <div class="directory-phone">
                            <span>{{ directory.personal_phone }}</span>
                            <i @click="callPhone(directory.personal_phone)" v-if="directory.personal_phone" style="cursor: pointer">
                              <PhoneOutIcon />
                            </i>
                          </div>
                        </router-link>
                        <router-link :to="{}">
                          <div class="directory-phone">
                            <span>{{ directory.additional_phone }}</span>
                            <i @click="callPhone(directory.additional_phone)" v-if="directory.additional_phone" style="cursor: pointer">
                              <PhoneOutIcon />
                            </i>
                          </div>
                        </router-link>
                        <router-link :to="{}" @click.native="openEmployee(directory)">{{ directory.email }}</router-link>
                        <router-link :to="{}" @click.native="openEmployee(directory)">{{ directory.address }}</router-link>
                        <router-link :to="{}" @click.native="openEmployee(directory)">{{ directory.department }}</router-link>
                        <router-link :to="{}" @click.native="openEmployee(directory)">{{ directory.subdivision }}</router-link>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td colspan="1">Нет результатов</td>
                </tr>
                </tbody>
              </table>
            </mq-layout>
          </div>
          <mq-layout :mq="[ 'xs', 'sm', 'xsm', 'md' ]" class="tbl__footer">
            <div class="tbl__footer__item left"></div>
            <div class="tbl__footer__item">
              <paginate :page-count="pageCount" :value="page + 1" :click-handler="setPage" prev-text="&lsaquo;" next-text="&rsaquo;" container-class="tbl__pagination vgt-pull-right">
                <span slot="prevContent">
                  <ArrowLeftIcon/>
                </span>
                <span slot="nextContent">
                  <ArrowRightIcon/>
                </span>
              </paginate>
            </div>
            <div class="tbl__footer__item right">
              <label class="tbl__pagination__info">
                Показано
                <span class="bold">{{ perPage }}</span>
                из
                <span class="bold">{{ allDirectoriesMeta }}</span>
              </label>
            </div>
          </mq-layout>
          <LsgModal attention v-if="showDeleteModal" @close="showDeleteModal = !showDeleteModal">
            <template slot="ico">
              <AttentionIco/>
            </template>
            <template slot="body">
              <h2>Вы уверены?</h2>
              <p>После удаления данные не могут быть восстановлены</p>
            </template>
            <template slot="footer">
              <button class="button button__red" @click="removeDirectories">Удалить</button>
              <button class="button" @click="showDeleteModal = !showDeleteModal">Отмена</button>
            </template>
          </LsgModal>
        </div>
      </div>
      <div class="scrollToTop" @click="scrollToTop">
        <svg width="50" height="50" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 6L8 10L12 6" stroke="#FF8000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import gql from 'graphql-tag'
import LsgModal from '@/components/ui/LsgModal'
import AttentionIco from '@/components/svg/attention'
import DELETE_COMPANIES from '@/graphql/mutations/DeleteCompanies.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import ArrowLeftIcon from '@/components/svg/arrow_left'
import ArrowRightIcon from '@/components/svg/arrow_right'
import FilterIco from '@/components/svg/new/Filter'
import SettingsIcon from '@/components/svg/settings'
import PhoneOutIcon from '../../components/svg/new/PhoneOutIcon'
// import MAKE_CALL from '@/graphql/mutations/MakeCall.gql'
import SelectComponent from '@/components/ui/GraphInputs/SelectInput'

const _graphQlHelper = new GraphQLHelper()

export default {
  components: {
    ArrowRightIcon,
    ArrowLeftIcon,
    LsgModal,
    AttentionIco,
    FilterIco,
    PhoneOutIcon,
    SelectComponent,
    SettingsIcon
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_HTTP,
      showDeleteModal: false,
      title: 'Телефонный справочник',
      links: [
        {
          name: 'Главная',
          route: 'home'
        },
        {
          name: 'Телефонный справочник'
        }
      ],
      page: 0,
      perPage: 100,
      sort: {
        field: 'name',
        order: 'asc'
      },
      filter: {
        name: [],
        department: [],
        subdivision: [],
        workphone: '',
        q: ''
      },
      directoryCheck: false,
      subdivisionList: [],
      departmentList: [],
      nameList: [],
      allDirectoriesMeta: 0
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.allDirectoriesMeta / this.perPage)
    },
    getDirectoryFilter () {
      let currentFilter = {}
      if (this.filter.name) currentFilter.name = this.filter.name
      if (this.filter.subdivision) currentFilter.subdivision = this.filter.subdivision
      if (this.filter.department) currentFilter.department = this.filter.department
      if (this.filter.q) currentFilter.q = this.filter.q
      return currentFilter
    }
  },
  apollo: {
    allDirectoriesMeta () {
      return {
        query: gql`query($filter: DirectoryFilter) {
          _allDirectoriesMeta(filter: $filter) {
            count
          }
        }`,
        variables () {
          return {
            filter: this.getDirectoryFilter
          }
        },
        update: data => {
          return data._allDirectoriesMeta.count || 0
        }
      }
    }
  },
  created () {
    this.page = this.$store.state.data.directories.page
    this.perPage = this.$store.state.data.directories.perPage
    this.sort = this.$store.state.data.directories.sort
    this.filter = this.$store.state.data.directories.filter
  },
  beforeRouteLeave (to, from, next) {
    this.$store.state.data.directories.page = this.page
    this.$store.state.data.directories.perPage = this.perPage
    this.$store.state.data.directories.sort = this.sort
    this.$store.state.data.directories.filter = this.filter
    next()
  },
  methods: {
    scrollToTop () {
      scrollTo({ top: 0, behavior: 'smooth' })
    },
    openEmployee (value) {
      this.$store.state.employeeData = value
      this.$store.state.showEmployee = true
    },
    showDirectoryMenu () {
      this.$store.state.showDirectoryMenu = true
    },
    async callPhone (phone) {
      if (!phone || !this.$store.state.me.workphone) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не указан телефон'
        })
        return
      }
      const callWindow = window.open(`http://192.168.10.3/amocrm/amocrm.php?_login=amocrm&_secret=mWYd1BU9hmWuezov&_action=call&to=${phone}&as=Amocrm&from=${this.$store.state.me.workphone}&rand=0.9550694509986963`)
      setTimeout(() => {
        callWindow.close()
      }, 1_000)
    },
    async departmentConvert (directory) {
      const departments = this.$store.state.allDepartments
      return departments
      // const departments = directory
      // return [...new Set(departments.map(elem => elem.department))]
    },
    async subdivisionConvert (directory) {
      const subdivisions = this.$store.state.allSubdivisions
      return subdivisions
      // const subdivisions = directory
      // return [...new Set(subdivisions.map(elem => elem.subdivision))]
    },
    nameConvert (directory) {
      const names = directory
      return [...new Set(names.map(elem => elem.name))]
    },
    selectedRowsChange ({ selectedRows }) {
      this.$store.state.checkedDirectories = selectedRows
    },
    perPageChanged (props) {
      props.perPageChanged({ currentPerPage: this.perPage })
    },
    removeDirectories () {
      this.$apollo.mutate({
        mutation: DELETE_COMPANIES,
        variables: {
          ids: this.$store.state.checkedDirectories
        }
      }).then(() => {
        this.$router.go()
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    setPage (page) {
      this.page = page - 1
    },
    onSubdivisionChange (e) {
      this.filter.subdivision = e
      this.setPage(1)
    },
    onDepartmentChange (e) {
      this.filter.department = e
      this.setPage(1)
    },
    onNameChange (e) {
      this.filter.name = e
      this.setPage(1)
    },
    async doneLoadingDirectories ({ data }) {
      if (!this.directoryCheck) {
        data.allDirectories = data.allDirectories.map(elem => {
          elem = {
            ...elem,
            expanded: true
          }
          return elem
        })
        this.departmentList = this.departmentConvert(data.allDirectories)
        this.subdivisionList = this.subdivisionConvert(data.allDirectories)
        this.nameList = this.nameConvert(data.allDirectories)
        this.directoryCheck = true
      }
      this.$store.commit('allDirectories', { data, isMore: false })
    },
    getDate (datetime) {
      const date = new Date(datetime)
      const options = {
        month: 'long',
        day: 'numeric'
      }
      return date.toLocaleString('ru', options)
    },
    sortingField2 (params) {
      if (params[0].type === 'none') {
        this.sort = {
          field: 'createdAt',
          order: 'desc'
        }
        return
      }
      this.sort.order = params[0].type
      this.sort.field = params[0].field
    },
    sortingField (columnName) {
      if (columnName === this.sort.field) this.sort.order = this.sort.order === 'asc' ? 'desc' : 'asc'
      this.sort.field = columnName
    },
    switchColumnModal () {
      this.$store.state.data.directories.columnModal = !this.$store.state.data.directories.columnModal
    }
  }
}
</script>

<style lang="stylus" scoped>
.scrollToTop {
  position fixed
  bottom 70px
  right 50px
  height 50px
  width 50px
  border-radius 50%
  z-index 3
  cursor pointer
  display flex
  justify-content center
  align-items center

  > svg {
    transform-origin: center center
    transform rotate(180deg)
  }
}

.directory-phone {
  display flex
  flex-direction row
  justify-content space-between
  width 100%
}

.directory-avatar {
  width: 70px;
  height: 70px;
  object-fit: cover;
  object-position: center;
  border-radius: 100%;
  border: 1px solid #f7f9fc;
}
</style>
